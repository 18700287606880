<template>
  <Auth>
    <Login />
  </Auth>
</template>

<script>
import Auth from "./Auth";
import Login from "@/components/Auth/Login";

export default {
  name: "LoginView",
  components: {
    Auth,
    Login,
  },
};
</script>
