var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var handleSubmit = ref.handleSubmit
          return [
            _c(
              "div",
              {
                staticClass: "h-screen w-full max-w-sm m-auto px-6 pt-24 pb-6"
              },
              [
                _c("loading", {
                  attrs: { active: _vm.isSubmitted, color: "#ff9300" },
                  on: {
                    "update:active": function($event) {
                      _vm.isSubmitted = $event
                    }
                  }
                }),
                _c("h3", { staticClass: "text-3xl font-bold mb-6" }, [
                  _vm._v(" Log in to your account ")
                ]),
                _c("div", { staticClass: "mb-6" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "flex w-full text-white border-2 border-gray-400 hover:border-gray-600 text-gray-700 focus:shadow-outline pl-2 py-2 h-12 rounded block",
                      on: {
                        click: function($event) {
                          return _vm.federatedSignIn("Google")
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "flex flex-shrink-0" }, [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "24",
                              height: "24",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: { fill: "none", "fill-rule": "evenodd" }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M20.64 12.2c0-.63-.06-1.25-.16-1.84H12v3.49h4.84a4.14 4.14 0 0 1-1.8 2.71v2.26h2.92a8.78 8.78 0 0 0 2.68-6.62z",
                                    fill: "#4285F4"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M12 21a8.6 8.6 0 0 0 5.96-2.18l-2.91-2.26a5.4 5.4 0 0 1-8.09-2.85h-3v2.33A9 9 0 0 0 12 21z",
                                    fill: "#34A853"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M6.96 13.71a5.41 5.41 0 0 1 0-3.42V7.96h-3a9 9 0 0 0 0 8.08l3-2.33z",
                                    fill: "#FBBC05"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M12 6.58c1.32 0 2.5.45 3.44 1.35l2.58-2.59A9 9 0 0 0 3.96 7.95l3 2.34A5.36 5.36 0 0 1 12 6.58z",
                                    fill: "#EA4335"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "flex-1 text-center block justify-center"
                        },
                        [_vm._v("Log in with Google")]
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "w-full mb-6" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "flex w-full text-white border-2 border-gray-400 hover:border-gray-600 active:bg-blue-700 text-gray-700 pl-2 py-2 h-12 rounded",
                      on: {
                        click: function($event) {
                          return _vm.federatedSignIn("Facebook")
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "flex flex-shrink-0 facebook" },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "24",
                                height: "24",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 24 24"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M20 3H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h8.61v-6.97h-2.34V11.3h2.34v-2c0-2.33 1.42-3.6 3.5-3.6 1 0 1.84.08 2.1.12v2.43h-1.44c-1.13 0-1.35.53-1.35 1.32v1.73h2.69l-.35 2.72h-2.34V21h4.59a1 1 0 0 0 .99-1V4a1 1 0 0 0-1-1z",
                                  fill: "currentColor"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M12.61 14.03V21h2.81v-6.98h2.34l.35-2.72h-2.69V9.57c0-.79.22-1.32 1.35-1.32h1.44V5.82c-.26-.04-1.1-.12-2.1-.12-2.08 0-3.5 1.27-3.5 3.6v2h-2.34v2.73h2.34z",
                                  fill: "#fff"
                                }
                              })
                            ]
                          )
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "flex-1 text-center block justify-center"
                        },
                        [_vm._v("Log in with Facebook")]
                      )
                    ]
                  )
                ]),
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.onSubmit)
                      }
                    }
                  },
                  [
                    _c("div", {}, [
                      _c("div", { staticClass: "w-full mb-1 flex" }, [
                        _c("hr", {
                          staticClass: "w-full flex-initial ml-4 my-6"
                        }),
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-center text-gray-500 mx-2 my-4 font-light text-xs"
                          },
                          [_vm._v(" OR ")]
                        ),
                        _c("hr", {
                          staticClass: "w-full flex-initial mr-4 my-6"
                        })
                      ]),
                      _c(
                        "div",
                        { staticClass: "w-full mb-2" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
                              attrs: { for: "grid-password" }
                            },
                            [_vm._v("Email")]
                          ),
                          _c("ValidationProvider", {
                            attrs: {
                              name: "email",
                              rules: "required|email",
                              mode: "eager"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.login.email,
                                            expression: "login.email"
                                          }
                                        ],
                                        staticClass:
                                          "appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 focus:outline-none",
                                        attrs: {
                                          type: "email",
                                          placeholder: "jane@example.com"
                                        },
                                        domProps: { value: _vm.login.email },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.login,
                                              "email",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-red-500 text-xs inline-block"
                                        },
                                        [_vm._v(_vm._s(errors[0]))]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "w-full mb-2" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
                              attrs: { for: "firstName" }
                            },
                            [_vm._v("Password")]
                          ),
                          _c("ValidationProvider", {
                            attrs: {
                              name: "password",
                              rules: "required",
                              mode: "eager"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.login.password,
                                            expression: "login.password"
                                          }
                                        ],
                                        staticClass:
                                          "appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 focus:outline-none",
                                        attrs: {
                                          type: "password",
                                          placeholder: _vm.passwordPlaceholder
                                        },
                                        domProps: { value: _vm.login.password },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.login,
                                              "password",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-red-500 text-xs inline-block"
                                        },
                                        [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "w-full mb-4" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "w-full shadow bg-orange-500 hover:bg-orange-400 focus:shadow-outline focus:outline-none text-white font-bold py-3 px-4 rounded",
                            attrs: { type: "submit" }
                          },
                          [_vm._v(" Login ")]
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "font-light mb-6 text-sm text-orange-500 underline",
                              attrs: { to: "/forgot" }
                            },
                            [_vm._v(" Forgot password? ")]
                          )
                        ],
                        1
                      ),
                      _c("div", [
                        _c(
                          "p",
                          { staticClass: "font-light mb-6 text-sm" },
                          [
                            _vm._v(" New to graduated.io? "),
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "font-light mb-6 text-sm text-orange-500 underline",
                                attrs: { to: "/signup" }
                              },
                              [_vm._v(" Signup ")]
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  ]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }