<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <div class="h-screen w-full max-w-sm m-auto px-6 pt-24 pb-6">
      <loading :active.sync="isSubmitted" color="#ff9300" />
      <h3 class="text-3xl font-bold mb-6">
        Log in to your account
      </h3>

      <div class="mb-6">
        <button
          class="flex w-full text-white border-2 border-gray-400 hover:border-gray-600 text-gray-700 focus:shadow-outline pl-2 py-2 h-12 rounded block"
          @click="federatedSignIn('Google')"
        >
          <span class="flex flex-shrink-0">
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
              <g fill="none" fill-rule="evenodd">
                <path
                  d="M20.64 12.2c0-.63-.06-1.25-.16-1.84H12v3.49h4.84a4.14 4.14 0 0 1-1.8 2.71v2.26h2.92a8.78 8.78 0 0 0 2.68-6.62z"
                  fill="#4285F4"
                />
                <path
                  d="M12 21a8.6 8.6 0 0 0 5.96-2.18l-2.91-2.26a5.4 5.4 0 0 1-8.09-2.85h-3v2.33A9 9 0 0 0 12 21z"
                  fill="#34A853"
                />
                <path
                  d="M6.96 13.71a5.41 5.41 0 0 1 0-3.42V7.96h-3a9 9 0 0 0 0 8.08l3-2.33z"
                  fill="#FBBC05"
                />
                <path
                  d="M12 6.58c1.32 0 2.5.45 3.44 1.35l2.58-2.59A9 9 0 0 0 3.96 7.95l3 2.34A5.36 5.36 0 0 1 12 6.58z"
                  fill="#EA4335"
                />
              </g></svg></span
          ><span class="flex-1 text-center block justify-center"
            >Log in with Google</span
          >
        </button>
      </div>

      <div class="w-full mb-6">
        <button
          class="flex w-full text-white border-2 border-gray-400 hover:border-gray-600 active:bg-blue-700 text-gray-700 pl-2 py-2 h-12 rounded"
          @click="federatedSignIn('Facebook')"
        >
          <span class="flex flex-shrink-0 facebook">
            <svg
              width="24"
              height="24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                d="M20 3H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h8.61v-6.97h-2.34V11.3h2.34v-2c0-2.33 1.42-3.6 3.5-3.6 1 0 1.84.08 2.1.12v2.43h-1.44c-1.13 0-1.35.53-1.35 1.32v1.73h2.69l-.35 2.72h-2.34V21h4.59a1 1 0 0 0 .99-1V4a1 1 0 0 0-1-1z"
                fill="currentColor"
              />
              <path
                d="M12.61 14.03V21h2.81v-6.98h2.34l.35-2.72h-2.69V9.57c0-.79.22-1.32 1.35-1.32h1.44V5.82c-.26-.04-1.1-.12-2.1-.12-2.08 0-3.5 1.27-3.5 3.6v2h-2.34v2.73h2.34z"
                fill="#fff"
              /></svg></span
          ><span class="flex-1 text-center block justify-center"
            >Log in with Facebook</span
          >
        </button>
      </div>

      <form class="" @submit.prevent="handleSubmit(onSubmit)">
        <div class="">
          <div class="w-full mb-1 flex">
            <hr class="w-full flex-initial ml-4 my-6" />
            <span
              class="text-center text-gray-500 mx-2 my-4 font-light text-xs"
            >
              OR
            </span>
            <hr class="w-full flex-initial mr-4 my-6" />
          </div>

          <div class="w-full mb-2">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
              >Email</label
            >

            <ValidationProvider
              v-slot="{ errors }"
              name="email"
              rules="required|email"
              mode="eager"
            >
              <input
                v-model="login.email"
                class="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 focus:outline-none"
                type="email"
                placeholder="jane@example.com"
              />
              <span class="text-red-500 text-xs inline-block">{{
                errors[0]
              }}</span>
            </ValidationProvider>
          </div>

          <div class="w-full mb-2">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="firstName"
              >Password</label
            >
            <ValidationProvider
              v-slot="{ errors }"
              name="password"
              rules="required"
              mode="eager"
            >
              <input
                v-model="login.password"
                class="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 focus:outline-none"
                type="password"
                :placeholder="passwordPlaceholder"
              />
              <p class="text-red-500 text-xs inline-block">
                {{ errors[0] }}
              </p>
            </ValidationProvider>
          </div>
          <div class="w-full mb-4">
            <button
              class="w-full shadow bg-orange-500 hover:bg-orange-400 focus:shadow-outline focus:outline-none text-white font-bold py-3 px-4 rounded"
              type="submit"
            >
              Login
            </button>
          </div>
          <div class="mb-3">
            <router-link
              to="/forgot"
              class="font-light mb-6 text-sm text-orange-500 underline"
            >
              Forgot password?
            </router-link>
          </div>
          <div>
            <p class="font-light mb-6 text-sm">
              New to graduated.io?
              <router-link
                to="/signup"
                class="font-light mb-6 text-sm text-orange-500 underline"
              >
                Signup
              </router-link>
            </p>
          </div>
        </div>
      </form>
    </div>
  </ValidationObserver>
</template>

<script>
import Auth from "@aws-amplify/auth";
import Loading from "vue-loading-overlay";
import { LOGIN } from "@/store/actions.type";
import { mapGetters } from "vuex";

export default {
  name: "Login",
  components: {
    Loading,
  },
  data() {
    return {
      login: {
        email: "",
        password: "",
      },
      passwordPlaceholder: "\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022",
      isSubmitted: false,
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    async federatedSignIn(provider) {
      await Auth.federatedSignIn({ provider });
    },
    async onSubmit() {
      this.isSubmitted = true;

      await this.$store.dispatch(LOGIN, {
        username: this.login.email,
        password: this.login.password,
      });

      this.isSubmitted = false;

      /*if (this.isAuthenticated) {
        this.$router.push({ name: "profile" });
      }*/
    },
  },
};
</script>
